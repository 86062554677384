/* global BigInt, */
import forge from "node-forge";
import { useState } from "react";

import { solvePuzzle } from "src/cryptography/timelockPuzzle";
import {
    clearStorageValue,
    getStorageValue,
    setStorageValue,
} from "src/localStorage";

export const REVEALING_PREFIX = "revealing-";

export const getStorageId = (messageIdentifier) => {
    return `${REVEALING_PREFIX}${messageIdentifier}`;
};

const DEFAULT_STATUS = {
    solvedPuzzle: null,
    currentValue: null,
    currentIteration: 0,
    totalIterations: 0,
    progress: 0,
    worker: null,
    isSubmitting: false,
    error: null,
    retryNumberAfterError: 0,
};

// We need source of truth that's always up to date to be sure to not have issues
// with the sync of statuses between all the calls
const tlpStatusSourceOfTruth = {};

export default function useTLPsStatus(currentUserWalletAddress) {
    // maps messageIdentifier -> tlpData = {
    //      status: {
    //          solvedPuzzle: SolvedPuzzle || null, // set only if isFinished = true
    //          currentValue: IntermediateValue || null, // set once it started
    //          currentIteration: number,
    //          totalIterations: number,
    //          progress: number,
    //          worker: Worker || null,
    //          // There is no way to know from the state of the TLP if a tx is in progress
    //          // so we need to get this info from the lower level component
    //          isSubmitting: bool,
    //          error: null || string (string = error.message)
    //          retryNumberAfterError: number // the number of times a user tried to reveal the message without success
    //      }
    // }
    const [tlpsStatus, setTLPsStatus] = useState({});

    const getTLPStatus = (messageIdentifier) => {
        let status = tlpStatusSourceOfTruth[messageIdentifier];
        if (!status) {
            status = getStorageValue(
                getStorageId(messageIdentifier),
                null,
                currentUserWalletAddress,
            );
        }
        if (!status) {
            status = DEFAULT_STATUS;
        }

        const isStarted = status.progress > 0;

        return {
            ...status,
            isFinished: status.progress === 100,
            isInProgress: Boolean(status.worker),
            isPaused: isStarted && !status.worker,
            isStarted,
        };
    };

    const setInTLPStatus = (messageIdentifier, payload, override = false) => {
        const currentStatus = getTLPStatus(messageIdentifier);
        const newStatus = {
            ...(!override ? currentStatus : {}),
            ...payload,
        };

        tlpStatusSourceOfTruth[messageIdentifier] = newStatus;
        // Notify React of the change
        setTLPsStatus((workersMap) => ({
            ...workersMap,
            [messageIdentifier]: tlpStatusSourceOfTruth[messageIdentifier],
        }));

        const valueToStore = { ...newStatus };
        // We can't store the actual worker, so loading from storage should not contain that info
        delete valueToStore.worker;
        // These values don't make sense when loaded at App load
        valueToStore.isSubmitting = false;
        valueToStore.error = null;
        valueToStore.retryNumberAfterError = 0;

        setStorageValue(
            getStorageId(messageIdentifier),
            valueToStore,
            currentUserWalletAddress,
        );
    };

    const setRetryNumberAfterError = (
        messageIdentifier,
        retryNumberAfterError,
    ) => {
        setInTLPStatus(messageIdentifier, {
            retryNumberAfterError,
        });
    };

    const setSubmissionStatus = (messageIdentifier, isSubmitting) => {
        setInTLPStatus(messageIdentifier, {
            isSubmitting,
        });
    };

    const startTLP = async ({
        messageIdentifier,
        tlpData: {
            timeLockedKeyBytes,
            timeLockPuzzleModulusBytes,
            timeLockPuzzleBaseBytes,
            iterations,
            ciphertextBytes,
            ivBytes,
        },
        onReveal,
        onError,
    }) => {
        try {
            const status = getTLPStatus(messageIdentifier);

            if (status?.worker) {
                throw new Error("Worker already running!");
            }

            setInTLPStatus(messageIdentifier, {
                error: null,
            });

            const timeLockedKey = BigInt(timeLockedKeyBytes);
            const timeLockPuzzleModulus = BigInt(timeLockPuzzleModulusBytes);
            const timeLockPuzzleBase = BigInt(timeLockPuzzleBaseBytes);
            const timeLockPuzzleIterations = iterations;
            const encryptedSecretCiphertext = forge.util.createBuffer(
                forge.util.hexToBytes(ciphertextBytes.slice(2)),
            );
            const encryptedSecretIv = forge.util.hexToBytes(ivBytes.slice(2));

            let solvedPuzzle;
            if (status?.isFinished) {
                solvedPuzzle = {
                    ...status.solvedPuzzle,
                    key: BigInt(status.solvedPuzzle.key),
                };
            } else {
                console.time(`Puzzle reveal ${messageIdentifier}`);
                solvedPuzzle = await solvePuzzle(
                    timeLockPuzzleIterations,
                    timeLockedKey,
                    encryptedSecretCiphertext,
                    timeLockPuzzleModulus,
                    status?.currentValue &&
                        status.currentValue !== DEFAULT_STATUS.currentValue
                        ? BigInt(status.currentValue)
                        : timeLockPuzzleBase,
                    status ? status.currentIteration : 0,
                    encryptedSecretIv,
                    (currentIteration, currentValue) => {
                        setInTLPStatus(messageIdentifier, {
                            currentIteration,
                            currentValue,
                            totalIterations: iterations,
                            progress: (currentIteration / iterations) * 100,
                        });
                    },
                    (worker) => {
                        setInTLPStatus(messageIdentifier, { worker });
                    },
                );

                // Store end result
                setInTLPStatus(messageIdentifier, {
                    finished: true,
                    solvedPuzzle: {
                        ...solvedPuzzle,
                        key: solvedPuzzle.key.toString(),
                    },
                    currentIteration: iterations,
                    progress: 100,
                    worker: null,
                });

                console.timeEnd(`Puzzle reveal ${messageIdentifier}`);
            }

            await onReveal(solvedPuzzle);
        } catch (error) {
            const status = getTLPStatus(messageIdentifier);
            setInTLPStatus(messageIdentifier, {
                worker: null,
                error: error.message,
                isSubmitting: false,
                retryNumberAfterError: (status.retryNumberAfterError ?? 0) + 1,
            });

            if (onError) {
                onError(error);
            } else {
                console.error("Error revealing TLP", error);
            }
        }
    };

    const pauseTLP = (messageIdentifier) => {
        const status = getTLPStatus(messageIdentifier);

        if (!status?.worker) {
            throw new Error("No worker to pause!");
        }
        status.worker.terminate();
        console.timeEnd(`Puzzle reveal ${messageIdentifier}`);

        setInTLPStatus(messageIdentifier, { worker: null });
    };

    const clearTLP = (messageIdentifier) => {
        setInTLPStatus(
            messageIdentifier,
            {
                totalIterations: 0,
                currentIteration: 0,
                progress: 0,
                worker: null,
                retryNumberAfterError: 0,
                error: null,
            },
            true,
        );

        clearStorageValue(
            getStorageId(messageIdentifier),
            currentUserWalletAddress,
        );
    };

    return {
        tlpsStatus,
        startTLP,
        pauseTLP,
        clearTLP,
        getTLPStatus,
        setSubmissionStatus,
        setRetryNumberAfterError,
    };
}
