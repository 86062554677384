import { useDispatch } from "react-redux";

import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";

export default function useCopyToClipboard() {
    const dispatch = useDispatch();
    const copyToClipboard = async ({
        contentToCopy,
        successMessage,
        errorMessage,
    }) => {
        try {
            navigator.clipboard.writeText(contentToCopy);
            dispatch(
                setSnackbarFeedback({
                    type: "success",
                    message: successMessage,
                }),
            );
        } catch (error) {
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: "error",
                    message: errorMessage,
                }),
            );
        }
    };
    return copyToClipboard;
}
