import { Stack, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";

const PublisherGrade = ({ score, size = 15, ...props }) => {
    const gradeLetterAndColorFromScore = useMemo(() => {
        let grade;
        if (score >= 0.9) {
            grade = {
                letter: "A",
                color: "#00D509",
            };
        } else if (score >= 0.8) {
            grade = {
                letter: "B",
                color: "#9ED500",
            };
        } else if (score >= 0.7) {
            grade = {
                letter: "C",
                color: "#F4F900",
            };
        } else if (score >= 0.6) {
            grade = {
                letter: "D",
                color: "#F9A400",
            };
        } else {
            grade = {
                letter: "F",
                color: "#F90000",
            };
        }
        return grade;
    }, [score]);

    return (
        gradeLetterAndColorFromScore && (
            <Stack
                {...props}
                sx={{
                    backgroundColor: gradeLetterAndColorFromScore.color,
                    width: size,
                    height: size,
                    borderRadius: "4px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Tooltip title={`Quality score: ${Math.floor(score * 100)}%`}>
                    <Typography
                        lineHeight={`${size * 0.67}px`}
                        fontSize={`${size * 0.67}px`}
                        fontWeight={700}
                        color="background.main"
                        textAlign="center"
                    >
                        {gradeLetterAndColorFromScore.letter}
                    </Typography>
                </Tooltip>
            </Stack>
        )
    );
};

export default PublisherGrade;
