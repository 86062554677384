import { openDB } from "idb";

import { readPublicKey } from "src/services/api";

const KEY_DB_NAME = "cryptoKeys";
const KEY_STORE_NAME = "keys";

async function updateKeys(keyStore) {
    const cursor = await keyStore.openCursor();
    while (cursor) {
        let key = cursor.value;
        const address = cursor.key;
        if (key instanceof CryptoKey) {
            const { publicKey: addressPublicKey } =
                await readPublicKey(address);

            key = {
                privateKey: key,
                publicKey: addressPublicKey,
            };
            cursor.update(key);
        }
        cursor.continue();
    }
}

async function deleteKeys(keyStore) {
    const cursor = await keyStore.openCursor();
    while (cursor) {
        cursor.delete();
        await cursor.continue();
    }
}

const initializeAndOpenCryptoKeysDB = async () => {
    return await openDB(KEY_DB_NAME, 3, {
        upgrade(db, oldVersion, newVersion, transaction) {
            let keyStore;
            switch (oldVersion) {
                case 0:
                    console.log("Initializing db");
                    if (!db.objectStoreNames.contains(KEY_STORE_NAME)) {
                        db.createObjectStore(KEY_STORE_NAME);
                    }
                    break;
                case 1:
                    console.log("Updating keys");
                    keyStore = transaction.objectStore(KEY_STORE_NAME);
                    updateKeys(keyStore);
                    break;
                case 2:
                    console.log("Deleting keys");
                    keyStore = transaction.objectStore(KEY_STORE_NAME);
                    deleteKeys(keyStore);
                    break;
                default:
                    break;
            }
        },
    });
};

export const saveKeys = async (keys, id) => {
    const db = await initializeAndOpenCryptoKeysDB();
    await db.put(KEY_STORE_NAME, keys, id);
};

export const getKeys = async (id) => {
    const db = await initializeAndOpenCryptoKeysDB();
    const keys = await db.get(KEY_STORE_NAME, id);
    return keys;
};
