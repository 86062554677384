import { Address } from "viem";

const getNamespacedKey = (key: string, namespace: Address | string | null) => {
    if (namespace) {
        key = `${key}-${namespace}`;
    }
    return key;
};

export const getStorageValue = <T>(
    key: string,
    defaultValue: T,
    namespace: Address | string | null = null,
): T => {
    if (localStorage === null) {
        console.error("localStorage is null (private mode or something)");
        return defaultValue;
    }

    // getting stored value
    let saved = localStorage.getItem(getNamespacedKey(key, namespace));
    if (!saved) {
        // try getting non-namespaced item to allow backwards compatibility
        saved = localStorage.getItem(key);
    }

    let initial = defaultValue;
    if (saved) {
        try {
            initial = JSON.parse(saved);
        } catch (e) {
            console.warn("Could not decode locally saved data");
            console.warn(e);
        }
    }

    return initial || defaultValue;
};

export const setStorageValue = <T>(
    key: string,
    value: T,
    namespace: Address | string | null = null,
) => {
    if (localStorage === null) {
        console.error("localStorage is null (private mode or something)");
        return;
    }

    localStorage.setItem(
        getNamespacedKey(key, namespace),
        JSON.stringify(value, (_key, value) =>
            typeof value === "bigint" ? value.toString() : value,
        ),
    );
};

export const clearStorageValue = (
    key: string,
    namespace: string | null = null,
) => {
    if (localStorage === null) {
        console.error("localStorage is null (private mode or something)");
        return;
    }

    localStorage.removeItem(getNamespacedKey(key, namespace));
    // backwards compatibility
    localStorage.removeItem(key);
};
