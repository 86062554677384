import { ContentCopy } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

import useCopyToClipboard from "src/hooks/useCopyToClipboard";

import AddressDisplay from "src/components/AddressDisplay";
import ModalButton from "src/components/ModalButton";

import BaseIcon from "src/assets/base-logo.svg?react";
import EthereumIcon from "src/assets/ethereum.svg?react";
import { generateRelayLink } from "src/utils";

export default function DepositOptions({ address }) {
    const copyToClipboard = useCopyToClipboard();

    return (
        <Stack gap={2} textAlign="left">
            <ModalButton
                startIcon={
                    <BaseIcon fontSize="xs" style={{ color: "white" }} />
                }
                label="Deposit ETH from Base"
                labelledby="deposit-eth-from-base"
                describedby="deposit-eth-from-another-wallet-on-base"
                fullWidth
            >
                {({ handleClose }) => (
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Typography variant="h6" textAlign="left">
                            Deposit ETH from Base
                        </Typography>
                        <Typography color="secondary" textAlign="left">
                            Send ETH from another wallet on the Base network to
                            this wallet: <AddressDisplay address={address} />
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            startIcon={<ContentCopy fontSize="xs" />}
                            onClick={() => {
                                copyToClipboard({
                                    contentToCopy: address,
                                    successMessage:
                                        "Address copied to clipboard",
                                    errorMessage:
                                        "Could not copy your address, sorry. Please try again",
                                });
                                handleClose();
                            }}
                        >
                            Copy address
                        </Button>
                    </Box>
                )}
            </ModalButton>
            <Button
                variant="contained"
                startIcon={
                    <EthereumIcon fontSize="xs" style={{ color: "white" }} />
                }
                fullWidth
                onClick={() => {
                    const relayLink = generateRelayLink(address);
                    window.open(relayLink, "_blank");
                }}
            >
                Bridge ETH with Relay
            </Button>
        </Stack>
    );
}
