import {
    Alert,
    AlertProps,
    Button,
    ButtonProps,
    Link,
    Snackbar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
    ISnackbarFeedbackState,
    SeverityType,
    resetSnackbarFeedback,
    selectSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

function ConnectedSnackbar() {
    const dispatch = useDispatch();
    const feedback = useSelector(selectSnackbarFeedback);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { info, error, hash, success, warning, action } = feedback;

    const isOpen = Boolean(info || error || hash || success || warning);
    const getSeverity = (feedback: ISnackbarFeedbackState) => {
        return Object.entries(feedback).reduce((result, [key, value]) => {
            if (value && key !== "action" && key !== "hash") {
                result = key as SeverityType;
            }
            return result;
        }, SeverityType.INFO);
    };
    const severity = getSeverity(feedback);

    let actionButton = null;
    if (action) {
        actionButton = (
            <Button
                color={severity satisfies ButtonProps["color"]}
                size="small"
                onClick={action.onClick}
            >
                {action.text}
            </Button>
        );
    }

    if (hash) {
        actionButton = (
            <Link
                href={`${import.meta.env.VITE_BLOCK_EXPLORER_URL}/tx/${hash}`}
                target="_blank"
            >
                <Button
                    color={severity satisfies ButtonProps["color"]}
                    size="small"
                >
                    View transaction
                </Button>
            </Link>
        );
    }

    const handleClose = () => {
        dispatch(resetSnackbarFeedback());
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: isMobile ? "top" : "bottom",
                horizontal: isMobile ? "center" : "left",
            }}
            open={isOpen}
            autoHideDuration={3_000}
            onClose={handleClose}
            sx={{ pointerEvents: "none" }}
        >
            <Alert
                onClose={handleClose}
                severity={severity satisfies AlertProps["color"]}
                action={actionButton}
                sx={{ pointerEvents: "auto", alignItems: "center" }}
            >
                {info ||
                    error ||
                    success ||
                    warning ||
                    (hash && "Processing transaction")}
            </Alert>
        </Snackbar>
    );
}

export default ConnectedSnackbar;
