import { Button, Link, Stack, Typography } from "@mui/material";
import { usePrivy } from "@privy-io/react-auth";

import ModalButton from "src/components/ModalButton";

import {
    LOGIN_METHODS,
    SHARE_ON_TWITTER_BASE_URL,
    SHARE_ON_WARPCAST_BASE_URL,
} from "src/constants";
import { SocialNetworkType } from "src/types/api-interfaces";

export default function ShareOnSocialNetworkButton({
    buttonTextOrFunction = "Share",
    label = buttonTextOrFunction,
    messageFunction,
    messageExtraParams,
    url,
    ...buttonProps
}) {
    const { user, ready } = usePrivy();
    const getFullMessage = (
        murmurSocialNetworkHandle,
        targetNetworkType = SocialNetworkType.TWITTER,
    ) =>
        encodeURIComponent(
            `${messageFunction({
                ...(messageExtraParams ? messageExtraParams : {}),
                murmurSocialNetworkHandle,
                targetNetworkType,
            })} ${url ? url : ""}`,
        );
    const twitterButton = (
        <Link
            href={`${SHARE_ON_TWITTER_BASE_URL}?text=${getFullMessage(
                import.meta.env.VITE_MURMUR_TWITTER_HANDLE,
                SocialNetworkType.TWITTER,
            )}`}
            target="_blank"
            rel="noreferrer noopener"
        >
            <Button
                variant="contained"
                color="yellow"
                fullWidth
                sx={{ ...buttonProps.sx }}
                {...buttonProps}
            >
                {`${typeof buttonTextOrFunction === "function" ? buttonTextOrFunction(SocialNetworkType.TWITTER) : buttonTextOrFunction} on X`}
            </Button>
        </Link>
    );
    const farcasterButton = (
        <Link
            href={`${SHARE_ON_WARPCAST_BASE_URL}?text=${getFullMessage(
                import.meta.env.VITE_MURMUR_WARPCAST_HANDLE,
                SocialNetworkType.FARCASTER,
            )}`}
            target="_blank"
            rel="noreferrer noopener"
        >
            <Button
                variant="contained"
                color="yellow"
                fullWidth
                sx={{ ...buttonProps.sx }}
                {...buttonProps}
            >
                {`${typeof buttonTextOrFunction === "function" ? buttonTextOrFunction(SocialNetworkType.FARCASTER) : buttonTextOrFunction} on Farcaster`}
            </Button>
        </Link>
    );
    if (!ready) {
        return null;
    }
    if (
        LOGIN_METHODS.includes(SocialNetworkType.FARCASTER) &&
        (!user || user?.linkedAccounts.length > 2)
    ) {
        return (
            <ModalButton
                label={`${typeof buttonTextOrFunction === "function" ? buttonTextOrFunction(SocialNetworkType.TWITTER) : buttonTextOrFunction} on X/Farcaster`}
                labelledby="share-on-social-network"
                describedby="share-on-social-network"
                color="yellow"
                fullWidth
                {...buttonProps}
            >
                {({ handleClose }) => (
                    <Stack gap={2}>
                        <Typography variant="h6">
                            {typeof buttonTextOrFunction === "function"
                                ? buttonTextOrFunction(
                                      SocialNetworkType.TWITTER,
                                  )
                                : buttonTextOrFunction}
                        </Typography>
                        <Stack gap={1}>
                            {twitterButton}
                            {farcasterButton}
                        </Stack>
                    </Stack>
                )}
            </ModalButton>
        );
    } else if (user?.farcaster) {
        return farcasterButton;
    } else {
        return twitterButton;
    }
}
