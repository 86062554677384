import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    getEpochCostFromContract,
    getEpochTimeFromContract,
} from "src/services/murmur";

import { AppDispatch, RootState } from "src/store";
import { MurmurContractType } from "src/types/murmurContractType";

interface IEpochSettingsState {
    loaded: boolean;
    duration: number;
    cost: number;
}

export const epochSettingsSlice = createSlice({
    name: "epochSettings",
    initialState: {
        duration: 0,
        cost: 0,
        loaded: false,
    } as IEpochSettingsState,
    reducers: {
        setEpochSettings: (
            state,
            { payload }: PayloadAction<Omit<IEpochSettingsState, "error">>,
        ) => {
            state.duration = payload.duration;
            state.cost = payload.cost;
            state.loaded = payload.loaded;
        },
    },
});

export const { setEpochSettings } = epochSettingsSlice.actions;

export const getEpochSettingsFromContract =
    (murmurContract: MurmurContractType) => async (dispatch: AppDispatch) => {
        const durationResponse = await getEpochTimeFromContract(murmurContract);
        const costResponse = await getEpochCostFromContract(murmurContract);
        dispatch(
            setEpochSettings({
                duration: Number(durationResponse),
                cost: Number(costResponse),
                loaded: true,
            }),
        );
    };

export const selectEpochSettings = (state: RootState) => state.epochSettings;

export default epochSettingsSlice.reducer;
