import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store";

export enum SeverityType {
    INFO = "info",
    ERROR = "error",
    WARNING = "warning",
    SUCCESS = "success",
}
type SnackbarFeedbackActionType = {
    onClick: () => void;
    text: string;
};
export interface ISnackbarFeedbackState extends Record<SeverityType, string> {
    hash: string;
    action: SnackbarFeedbackActionType | null;
}

const initialState: ISnackbarFeedbackState = {
    info: "",
    error: "",
    warning: "",
    hash: "",
    success: "",
    action: null,
};

export const snackbarFeedbackSlice = createSlice({
    name: "snackbarFeedback",
    initialState,
    reducers: {
        overrideSnackbarFeedbackObject: (
            state,
            { payload }: PayloadAction<Record<SeverityType, string>>,
        ) => {
            Object.keys(payload).forEach((key) => {
                state[key as SeverityType] = payload[key as SeverityType];
            });
        },
        setSnackbarFeedback: (
            state,
            {
                payload: { type, message, hash, action },
            }: PayloadAction<{
                type: SeverityType;
                message: string;
                hash?: string;
                action?: SnackbarFeedbackActionType;
            }>,
        ) => {
            // Reset message
            Object.keys(initialState).forEach((key) => {
                const k = key as keyof ISnackbarFeedbackState;
                state[k] = initialState[k] as never;
            });
            // Set new message
            state[type] = `${message}`;
            if (action) {
                state.action = action;
            }
            if (hash) {
                state.hash = hash;
            }
        },
        resetSnackbarFeedback: (state) => {
            Object.keys(initialState).forEach((key) => {
                const k = key as keyof ISnackbarFeedbackState;
                state[k] = initialState[k] as never;
            });
        },
    },
});

export const { setSnackbarFeedback, resetSnackbarFeedback } =
    snackbarFeedbackSlice.actions;

export function selectSnackbarFeedback(state: RootState) {
    return state.snackbarFeedback;
}

export default snackbarFeedbackSlice.reducer;
