import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store";

interface INoConnectionState {
    status: boolean;
}

const initialState: INoConnectionState = {
    status: false,
};

export const noConnectionSlice = createSlice({
    name: "noConnection",
    initialState,
    reducers: {
        setNoConnection: (state, { payload }: PayloadAction<boolean>) => {
            state.status = payload;
        },
        resetNoConnection: (state) => {
            state.status = initialState.status;
        },
    },
});

export const { setNoConnection, resetNoConnection } = noConnectionSlice.actions;

export function selectNoConnection(state: RootState) {
    return state.noConnection.status;
}

export default noConnectionSlice.reducer;
