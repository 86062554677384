import { Stack, Typography } from "@mui/material";

export default function FullscreenMessage({
    label,
    icon = null,
    sx = {},
    ...props
}) {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100vh", p: 2, textAlign: "center", ...sx }}
            {...props}
        >
            {icon}
            {label}
        </Stack>
    );
}
