import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import OneSignal from "react-onesignal";
import { Address } from "viem";

import { auth, logout } from "src/services/api";
import { addProfileData, removeProfileData } from "src/slices/profileSlice";

import { AppDispatch, RootState } from "src/store";

interface IAuthState {
    needsToGenerateKey: boolean;
    isLoggingOut: boolean;
    currentUserWalletAddress?: Address;
    isConnectingPrivy: boolean;
    isLoadingAuth: boolean;
}

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        needsToGenerateKey: true,
        isLoggingOut: false,
        currentUserWalletAddress: undefined,
        isConnectingPrivy: true,
        isLoadingAuth: false,
    } as IAuthState,
    reducers: {
        clearProfile: (state) => {
            state.currentUserWalletAddress = undefined;
        },
        setIsLoadingAuth: (state, action: PayloadAction<boolean>) => {
            state.isLoadingAuth = action.payload;
        },
        setIsLoggingOut: (state, action: PayloadAction<boolean>) => {
            state.isLoggingOut = action.payload;
        },
        setCurrentUserWalletAddress: (
            state,
            action: PayloadAction<Address | undefined>,
        ) => {
            state.currentUserWalletAddress = action.payload;
        },
        setIsConnectingPrivy: (state, action: PayloadAction<boolean>) => {
            state.isConnectingPrivy = action.payload;
        },
    },
});

export const {
    clearProfile,
    setIsConnectingPrivy,
    setIsLoggingOut,
    setCurrentUserWalletAddress,
    setIsLoadingAuth,
} = authSlice.actions;
export default authSlice.reducer;

export function selectIsConnected(state: RootState) {
    const { currentUserWalletAddress } = state.auth;
    let currentUserProfile;
    if (currentUserWalletAddress) {
        currentUserProfile =
            state.profiles.profileByAddress[currentUserWalletAddress];
    }

    return Boolean(currentUserProfile && currentUserWalletAddress);
}

export function selectIsLoadingAuth(state: RootState) {
    const { isLoadingAuth, isConnectingPrivy } = state.auth;
    return Boolean(isLoadingAuth || isConnectingPrivy);
}

export function selectIsLoggingOut(state: RootState) {
    return state.auth.isLoggingOut;
}

export function selectCurrentUserWalletAddress(state: RootState) {
    return state.auth.currentUserWalletAddress;
}

export const logoutFromAll =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setIsLoggingOut(true));
        try {
            OneSignal.logout();
        } catch (e) {
            console.error("Could not logout user from OneSignal", e);
        }
        await logout();
        const { currentUserWalletAddress } = getState().auth;
        if (currentUserWalletAddress) {
            dispatch(removeProfileData(currentUserWalletAddress));
        }
        dispatch(clearProfile());
        // the setIsLoggingOut(false) is done in the start page to avoid infinite loop
    };

type HandleAuthConnectionType = {
    walletAddress: Address;
    handleDuplicateTwitterAccountError: () => void;
    referralCode?: string;
};
export const handleAuthConnection =
    ({ walletAddress, referralCode }: HandleAuthConnectionType) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setIsLoadingAuth(true));
            dispatch(setCurrentUserWalletAddress(walletAddress));
            const profilePayload = await auth(
                referralCode ? { referralCode } : undefined,
            );
            dispatch(
                addProfileData({
                    walletAddress,
                    profile: profilePayload,
                }),
            );
        } catch (error) {
            dispatch(setCurrentUserWalletAddress(undefined));
            if (
                isAxiosError(error) &&
                error.response?.data?.error ===
                    "This twitter id is already used"
            ) {
                console.error("This twitter id is already used");
            } else {
                console.error("Error in handleAuthConnection", error);
            }
        } finally {
            dispatch(setIsLoadingAuth(false));
        }
    };
