import { io } from "socket.io-client";

import { waitFor } from "src/utils";
import { getPrivyAccessTokenOrLogout } from "src/utils/getPrivyAccessTokenOrLogout";

export const socket = io(
    `${
        import.meta.env.VITE_SOCKETS_HOST || import.meta.env.VITE_BACKEND_HOST
    }/`,
    {
        autoConnect: false,
        withCredentials: true,
        reconnectionDelay: 10_000,
        auth: async (cb) => {
            // we wait for privy connection and set of privy-token
            await waitFor(1_000, async () => {
                const privyAccessToken =
                    await getPrivyAccessTokenOrLogout("socket auth");
                return Boolean(privyAccessToken);
            });
            return cb();
        },
    },
);
