import { configureStore } from "@reduxjs/toolkit";

import authReducer from "src/slices/authSlice";
import contractSettingsReducer from "src/slices/contractSettingsSlice";
import epochSettingsReducer from "src/slices/epochSettingsSlice";
import feedsReducer from "src/slices/feedsSlice";
import lotteriesReducer from "src/slices/lotteriesSlice";
import noConnectionReducer from "src/slices/noConnectionSlice";
import notificationsReducer from "src/slices/notificationsSlice";
import profileReducer from "src/slices/profileSlice";
import snackbarFeedbackReducer from "src/slices/snackbarFeedbackSlice";
import subscriptionsReducer from "src/slices/subscriptionsSlice";
import topicsReducer from "src/slices/topicsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        feeds: feedsReducer,
        profiles: profileReducer,
        snackbarFeedback: snackbarFeedbackReducer,
        subscriptions: subscriptionsReducer,
        epochSettings: epochSettingsReducer,
        contractSettings: contractSettingsReducer,
        notifications: notificationsReducer,
        topics: topicsReducer,
        noConnection: noConnectionReducer,
        lotteries: lotteriesReducer,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
