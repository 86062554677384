import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    getAllPushNotificationStatus,
    getPushNotificationStatus,
} from "src/services/api";

import { AppDispatch, RootState } from "src/store";
import { NotificationType } from "src/types/api-interfaces";

type CurrentAccountNotificationStatusesByNotificationTypeAndTopicIdType =
    Record<NotificationType, Record<number, boolean>>;
type PushNotificationLoadingType = Record<NotificationType, boolean>;

const initialLoadingState: PushNotificationLoadingType | unknown = {};
const initialStatuses:
    | CurrentAccountNotificationStatusesByNotificationTypeAndTopicIdType
    | unknown = {};

Object.values(NotificationType).forEach((key) => {
    (initialLoadingState as PushNotificationLoadingType)[key] = false;
    (
        initialStatuses as CurrentAccountNotificationStatusesByNotificationTypeAndTopicIdType
    )[key] = {};
});

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        loading: initialLoadingState as PushNotificationLoadingType,
        currentAccountNotificationStatusesByNotificationTypeAndTopicId:
            initialStatuses as CurrentAccountNotificationStatusesByNotificationTypeAndTopicIdType,
    },
    reducers: {
        setLoading: (
            state,
            {
                payload: { type, status },
            }: PayloadAction<{ type: NotificationType; status: boolean }>,
        ) => {
            state.loading[type] = status;
        },
        setAllLoading: (
            state,
            { payload: { status } }: PayloadAction<{ status: boolean }>,
        ) => {
            Object.keys(state.loading).forEach(
                (k) => (state.loading[k as NotificationType] = status),
            );
        },
        setCurrentAccountNotificationStatus: (
            state,
            {
                payload,
            }: PayloadAction<{
                topicId: number;
                status: boolean;
                notificationType: NotificationType;
            }>,
        ) => {
            state.currentAccountNotificationStatusesByNotificationTypeAndTopicId[
                payload.notificationType
            ][payload.topicId] = payload.status;
        },
    },
});

export const {
    setLoading,
    setAllLoading,
    setCurrentAccountNotificationStatus,
} = notificationsSlice.actions;

export const getAllCurrentAccountNotificationStatuses =
    (topicId: number) => async (dispatch: AppDispatch) => {
        dispatch(setAllLoading({ status: true }));

        try {
            const response = await getAllPushNotificationStatus(topicId);
            Object.keys(response).forEach((notificationType) => {
                dispatch(
                    setCurrentAccountNotificationStatus({
                        notificationType: notificationType as NotificationType,
                        topicId,
                        status: response[notificationType as NotificationType],
                    }),
                );
            });
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setAllLoading({ status: false }));
        }
    };

export const getNotificationStatus =
    (notificationType: NotificationType, topicId: number) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: notificationType, status: true }));

        try {
            const fetchedAlertStatus = await getPushNotificationStatus(
                topicId,
                notificationType,
            );

            dispatch(
                setCurrentAccountNotificationStatus({
                    notificationType,
                    status: fetchedAlertStatus,
                    topicId,
                }),
            );
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading({ type: notificationType, status: false }));
        }
    };

export function selectLoading(type: NotificationType) {
    return function selectLoadingFromState(state: RootState) {
        return state.notifications.loading[type];
    };
}

export const selectCurrentAccountNotificationStatus =
    (notificationType: NotificationType, topicId: number) =>
    (state: RootState) => {
        return state.notifications
            .currentAccountNotificationStatusesByNotificationTypeAndTopicId[
            notificationType
        ][topicId];
    };

export default notificationsSlice.reducer;
