import { Button, Stack, Typography } from "@mui/material";

import SkipButton from "src/components/SkipButton";

export default function LotteryBaseModalContent({
    title,
    subtitle,
    buttonContent,
    skipButtonContent,
    children,
    onSkipButtonClick,
    onButtonClick,
    buttonProps = {},
    stackProps = {},
}) {
    return (
        <Stack gap={1} {...stackProps}>
            {title ? (
                <Typography
                    variant="h6"
                    align="center"
                    fontWeight="fontWeightBold"
                >
                    {title}
                </Typography>
            ) : null}
            {subtitle ? (
                <Typography
                    id="lottery-winner-description-part-1"
                    color="secondary"
                >
                    {subtitle}
                </Typography>
            ) : null}
            {children}
            {buttonContent ? (
                <Button
                    variant="contained"
                    onClick={onButtonClick}
                    sx={{ mt: 4 }}
                    {...buttonProps}
                >
                    {buttonContent}
                </Button>
            ) : null}
            {skipButtonContent ? (
                <SkipButton
                    onClick={onSkipButtonClick}
                    text={skipButtonContent}
                    showArrow={false}
                />
            ) : null}
        </Stack>
    );
}
