import { openDB } from "idb";

const DRAFT_DB_NAME = "draftDB";
const DRAFT_STORE_NAME = "drafts";

const initializeAndOpenCryptoKeysDB = async () => {
    return await openDB(DRAFT_DB_NAME, 1, {
        upgrade(db, oldVersion, newVersion, transaction) {
            switch (oldVersion) {
                case 0:
                    console.log("Initializing db");
                    if (!db.objectStoreNames.contains(DRAFT_STORE_NAME)) {
                        db.createObjectStore(DRAFT_STORE_NAME);
                    }
                    break;
                default:
                    break;
            }
        },
    });
};

export const saveDraft = async (draft, id) => {
    const db = await initializeAndOpenCryptoKeysDB();
    await db.put(DRAFT_STORE_NAME, draft, id);
};

export const getDraft = async (id) => {
    const db = await initializeAndOpenCryptoKeysDB();
    const draft = await db.get(DRAFT_STORE_NAME, id);
    return draft;
};
