import { usePrivy } from "@privy-io/react-auth";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { readPublicKey, savePublicKey } from "src/services/api";
import { getKeys, saveKeys } from "src/services/cryptoDB";
import { selectCurrentUserProfile } from "src/slices/profileSlice";
import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";

import { generateKeyPair as generateKeyPairFromWalletEncryption } from "src/cryptography/walletEncryption";

// We need to generate keys :
// - directly if profile?.hasSubscribedToAnyTopic and !savedKeys > useEffect whith the checkAndGenerateIfNeeded method, this hook is called directly in App.jsx
// - before joinFeedFromContract for new subscriber > generateKeyPairIfNeeded function that can be called before subscription
export default function useGenerateKeyPairIfNeeded({ walletAddress }) {
    const { signMessage } = usePrivy();
    const dispatch = useDispatch();
    const [savedKeys, setSavedKeys] = useState();
    const profile = useSelector(selectCurrentUserProfile);

    const generateKeyPair = useCallback(async () => {
        try {
            if (!profile?.keyDerivationNonce?.length) {
                throw new Error(
                    "You should be a connected user to do this action",
                );
            }
            const signMessageFunc = async ({ message }) =>
                await signMessage(message, {});
            const signedNonce = await signMessageFunc({
                message: profile.keyDerivationNonce,
            });
            const { privateKey, publicKey } =
                await generateKeyPairFromWalletEncryption(signedNonce);

            await savePublicKey(publicKey);
            await saveKeys({ privateKey, publicKey }, walletAddress);
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackbarFeedback({
                    type: "error",
                    message:
                        "Could not generate your personal key pair. Please inform our team.",
                }),
            );
        }
    }, [dispatch, profile?.keyDerivationNonce, signMessage, walletAddress]);

    useEffect(() => {
        const checkAndGenerateIfNeeded = async () => {
            let savedKeys = await getKeys(walletAddress);
            // check first subscription status to avoid unecessary backend calls
            if (profile?.hasSubscribedToAnyTopic) {
                const { publicKey: backendKey } =
                    await readPublicKey(walletAddress);
                if (!savedKeys || !backendKey) {
                    // user is already subscriber of a topic but doesn't have keys in local or backend > need to generate them
                    await generateKeyPair();
                    savedKeys = await getKeys(walletAddress);
                }
            }
            setSavedKeys(savedKeys);
        };
        if (walletAddress) {
            checkAndGenerateIfNeeded();
        }
    }, [
        dispatch,
        walletAddress,
        profile?.hasSubscribedToAnyTopic,
        generateKeyPair,
    ]);

    const generateKeyPairIfNeeded = useCallback(async () => {
        if (!savedKeys) {
            generateKeyPair();
        }
    }, [generateKeyPair, savedKeys]);

    return generateKeyPairIfNeeded;
}
