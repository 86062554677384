import { Stack, Typography } from "@mui/material";

import Page from "src/components/Page";

export default function PrivacyPolicyPage() {
    return (
        <Page>
            <Stack gap={2} py={5}>
                <Typography sx={{ fontSize: 30 }} component="h1">
                    Privacy Policy
                </Typography>
                <Typography>
                    At MURMUR, accessible from https://murmurprotocol.com/, one
                    of our main priorities is the privacy of our visitors. This
                    Privacy Policy document contains types of information that
                    is collected and recorded by MURMUR and how we use it.
                </Typography>
                <Typography>
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us. Our
                    Privacy Policy was generated with the help of GDPR Privacy
                    Policy Generator from GDPRPrivacyNotice.com
                </Typography>
                <Typography variant="h5">
                    General Data Protection Regulation (GDPR)
                </Typography>
                <Typography variant="h6">
                    We are a Data Controller of your information.
                </Typography>
                <Typography>
                    MURMUR legal basis for collecting and using the personal
                    information described in this Privacy Policy depends on the
                    Personal Information we collect and the specific context in
                    which we collect the information:
                    <ul>
                        <li>MURMUR needs to perform a contract with you </li>
                        <li>You have given MURMUR permission to do so</li>
                        <li>
                            Processing your personal information is in MURMUR
                            legitimate interests
                        </li>
                        <li>MURMUR needs to comply with the law </li>
                    </ul>
                    MURMUR will retain your personal information only for as
                    long as is necessary for the purposes set out in this
                    Privacy Policy. We will retain and use your information to
                    the extent necessary to comply with our legal obligations,
                    resolve disputes, and enforce our policies.
                </Typography>
                <Typography>
                    If you are a resident of the European Economic Area (EEA),
                    you have certain data protection rights. If you wish to be
                    informed what Personal Information we hold about you and if
                    you want it to be removed from our systems, please contact
                    us.
                </Typography>
                <Typography>
                    In certain circumstances, you have the following data
                    protection rights:
                    <ul>
                        <li>
                            The right to access, update or to delete the
                            information we have on you.
                        </li>
                        <li>The right of rectification.</li>
                        <li>The right to object. </li>
                        <li>The right of restriction. </li>
                        <li>The right to data portability </li>
                        <li>The right to withdraw consent</li>
                    </ul>
                </Typography>
                <Typography variant="h6">Log Files</Typography>
                <Typography>
                    MURMUR follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and a part of hosting services'
                    analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable.
                    The purpose of the information is for analyzing trends,
                    administering the site, tracking users' movement on the
                    website, and gathering demographic information.
                </Typography>
                <Typography variant="h6">Cookies and Web Beacons</Typography>
                <Typography>
                    Like any other website, MURMUR uses 'cookies'. These cookies
                    are used to store information including visitors'
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    users' experience by customizing our web page content based
                    on visitors' browser type and/or other information. For more
                    general information on cookies, please read "What Are
                    Cookies".
                </Typography>
                <Typography variant="h5">Privacy Policies</Typography>
                <Typography>
                    You may consult this list to find the Privacy Policy for
                    each of the advertising partners of MURMUR.
                </Typography>
                <Typography>
                    Third-party ad servers or ad networks uses technologies like
                    cookies, JavaScript, or Web Beacons that are used in their
                    respective advertisements and links that appear on MURMUR,
                    which are sent directly to users' browser. They
                    automatically receive your IP address when this occurs.
                    These technologies are used to measure the effectiveness of
                    their advertising campaigns and/or to personalize the
                    advertising content that you see on websites that you visit.
                </Typography>
                <Typography>
                    Note that MURMUR has no access to or control over these
                    cookies that are used by third-party advertisers.
                </Typography>
                <Typography variant="h5">
                    Third Party Privacy Policies
                </Typography>
                <Typography>
                    MURMUR's Privacy Policy does not apply to other advertisers
                    or websites. Thus, we are advising you to consult the
                    respective Privacy Policies of these third-party ad servers
                    for more detailed information. It may include their
                    practices and instructions about how to opt-out of certain
                    options.
                </Typography>
                <Typography>
                    You can choose to disable cookies through your individual
                    browser options. To learn how to manage cookies with
                    different web browsers, visit their respective websites for
                    detailed information.
                </Typography>
                <Typography variant="h5">Children's Information</Typography>
                <Typography>
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, participate in, and/or monitor and
                    guide their online activity.
                </Typography>
                <Typography>
                    MURMUR does not knowingly collect any Personal Identifiable
                    Information from children under the age of 13. Suppose you
                    think that your child provided this kind of information on
                    our website. In that case, we strongly encourage you to
                    contact us immediately and we will do our best efforts to
                    promptly remove such information from our records.
                </Typography>

                <Typography variant="h5">Online Privacy Policy Only</Typography>
                <Typography>
                    Our Privacy Policy applies only to our online activities and
                    is valid for visitors to our website with regards to the
                    information that they shared and/or collect in MURMUR. This
                    policy does not apply to any information collected offline
                    or via channels other than this website.
                </Typography>
                <Typography variant="h5">Consent</Typography>
                <Typography>
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                </Typography>
            </Stack>
        </Page>
    );
}
