import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Button } from "@mui/material";

import useNotificationToggle from "src/hooks/useNotificationToggle";

export default function PushNotificationButton({
    notificationType,
    topicId,
    enabledContent = "Stop notifications",
    disabledContent = "Get notifications",
    enabledIcon = <NotificationsActiveIcon />,
    disabledIcon = <NotificationsNoneIcon />,
    ...props
}) {
    const { loading, status, toggle } = useNotificationToggle({
        notificationType,
        topicId,
    });

    return (
        <Button
            fullWidth
            variant="contained"
            disabled={loading}
            onClick={toggle}
            {...props}
            startIcon={status ? enabledIcon : disabledIcon}
        >
            {status ? enabledContent : disabledContent}
        </Button>
    );
}
