import { useSelector } from "react-redux";

import useBalance from "src/hooks/useBalance";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";
import { selectContractSettings } from "src/slices/contractSettingsSlice";

// roughly 1 USD in Base ETH in 16/01/2024
// should cover the gas fee of most transactions
const LOW_BALANCE_THRESHOLD = 300000000000000n;

export default function useIsLowBalance(includeSubscriptionFee = false) {
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );
    const { subscriptionFee } = useSelector(selectContractSettings);
    const { balance, refetch } = useBalance({
        address: currentUserWalletAddress,
    });
    const low_balance_threshold =
        LOW_BALANCE_THRESHOLD +
        (includeSubscriptionFee ? BigInt(subscriptionFee) : 0n);

    const isLowBalance =
        typeof balance == "bigint" && balance <= low_balance_threshold;

    return { isLowBalance, refetch };
}
